import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { AppAsideModule, AppBreadcrumbModule, AppSidebarModule, } from '@coreui/angular';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule, routingComponents } from './app.routing';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule,BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { EmailService } from './core/services/email.service';
import { HttpClientModule } from '@angular/common/http'; 
import { NgHttpLoaderModule } from 'ng-http-loader';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MaterialComponents } from './core/material-ui';
import { UserService  } from "./core/services/user.service";
import { HeaderComponent } from './app/views/header/header.component';
import { FooterComponent } from './app/views/footer/footer.component';
import { CommonModule } from '@angular/common';
import { VideoPlayerService } from './core/services/videoPlayerService';
import { AccountModule } from './views/account/account.module';
import { CommonService } from "src/app/core/services/common.service";
import { AssetsService } from './core/services/assets.service';
import { jwPlayerService } from './views/jw-video-player/jwplayer.servies';
import { SearchModule } from './views/search/search.module';
import { PagenotfoundComponent } from './views/pagenotfound/pagenotfound.component';
import { ServerErrorComponent } from './views/server-error/server-error.component';
import { ContactusComponent } from './views/contactus/contactus.component';
import { MediaComponent } from './views/media/media.component';
import { PolicyComponent } from './views/policy/policy.component';
import { TermsComponent } from './views/terms/terms.component';
import { AdvertiseComponent } from './views/advertise/advertise.component';
import { PaymentModule } from './views/subscribe/payment/payment.module';
import { ChosePlanModule } from './views/subscribe/chose-plan/chose-plan.module';

import { AboutusComponent } from './views/aboutus/aboutus.component';
import { EventTrackingService } from './services/google/event-tracking.service';
import { LocalStorageService  } from './core/services/local-stroage.service';
import { DfpModule } from 'ngx-dfp';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppInitService } from './core/services/app-init.service';
import { DynamicTabComponent } from './views/dynamic-tab/dynamic-tab.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FaqComponent } from './views/faq/faq.component';
import { ChoosePlanV2Module } from './views/subscribe/choose-plan-v2/choose-plan-v2.module';
import { ContestComponent } from './views/contest/contest.component';
import { NgAisModule } from 'angular-instantsearch';


@NgModule({
  imports: [
    InfiniteScrollModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppAsideModule,
    CommonModule,
    AppBreadcrumbModule.forRoot(),
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    ReactiveFormsModule,
    SharedModule,  
    NoopAnimationsModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    TimepickerModule.forRoot(),
    MaterialComponents,
    AccountModule,
    SearchModule,
    PaymentModule,
    ChosePlanModule,
    ChoosePlanV2Module,
    MatTooltipModule,
    DfpModule.forRoot({
      idleLoad: true,
      enableVideoAds: true,
      personalizedAds: false, // Request non-personalized ads
      singleRequestMode: true,
      onSameNavigation: 'refresh',
    }),
    NgbModule,
    NgAisModule.forRoot()
    // AuthModule

  ],
  declarations: [
    AppComponent,
    routingComponents,
    HeaderComponent,
    FooterComponent,
    PagenotfoundComponent,
    ServerErrorComponent,
    ContactusComponent,
    MediaComponent,
    PolicyComponent,
    TermsComponent,
    AdvertiseComponent,
    AboutusComponent,
    DynamicTabComponent,
    FaqComponent,
    ContestComponent
   ],
  schemas:[
    NO_ERRORS_SCHEMA, 
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    EmailService, 
    LocalStorageService, 
    UserService, 
    VideoPlayerService, 
    CommonService, 
    AssetsService, 
    jwPlayerService, 
    EventTrackingService,
    { provide: APP_INITIALIZER, useFactory: configServiceFactory, deps: [AppInitService], multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents:[],
})
export class AppModule { }


export function  configServiceFactory(appInitService: AppInitService) {

  return (): Promise<any> => { 
    return appInitService.Init();
  }


  }
