import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

import * as algoliasearch from 'algoliasearch/lite';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { customOptionsForFourMiniSlider, customOptionsForBigSlider, justAdded, continueWatching, fourBigSlider } from "../../shared/owl-slider-config";
import { CatlougeTypeConstants } from '../../core/constants';
import { SearchService } from './search.service';
import { PageTitleService } from 'src/app/services/title/page-title.service';
import { CommonService } from "src/app/core/services/common.service";
import { UserService } from 'src/app/core';
import { PlayListService } from '../playlist/playlist/playlist.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { environment } from '../../../environments/environment';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
declare var $: any;
// const searchClient = algoliasearch(
//   'C2JLM0V1F2',
//   '2732f3d5e7c3eef0ef0b8e0e497a15ca'
// );

if (JSON.parse(localStorage.getItem('filterTypeData')) && JSON.parse(localStorage.getItem('filterTypeData')).search_engine) {
  if (JSON.parse(localStorage.getItem('filterTypeData')).search_engine == 'algolia') {
    var searchClient: any = algoliasearch(
      // 'C2JLM0V1F2',
      JSON.parse(localStorage.getItem('filterTypeData')).search_settings.app_id,
      // '2732f3d5e7c3eef0ef0b8e0e497a15ca'
      JSON.parse(localStorage.getItem('filterTypeData')).search_settings.api_key
    );
  } else {
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: JSON.parse(localStorage.getItem('filterTypeData')).search_settings != "" ? JSON.parse(localStorage.getItem('filterTypeData')).search_settings.key : "", //"KaOiHOYPSv7GR9Ir1ESqQTyrG9gajAhU", // Be sure to use an API key that only allows search operations
        nodes: [
          {
            host: JSON.parse(localStorage.getItem('filterTypeData')).search_settings.host, //"stage-search.digivive.com",
            // path: "", // Optional. Example: If you have your typesense mounted in localhost:8108/typesense, path should be equal to '/typesense'
            port: JSON.parse(localStorage.getItem('filterTypeData')).search_settings.port,
            protocol: JSON.parse(localStorage.getItem('filterTypeData')).search_settings.protocol, //"https",
          },
        ],
        cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
      },
      // The following parameters are directly passed to Typesense's search API endpoint.
      //  So you can pass any parameters supported by the search endpoint below.
      //  query_by is required.
      additionalSearchParameters: {
        query_by: "name,code,type,default_keyword,synopsis,genre_text",
        per_page: 20
      },
    });
    var searchClient = typesenseInstantsearchAdapter.searchClient;
  }
}
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  providers:[UserService, PlayListService, CommonService]
})
export class SearchComponent implements OnInit {
  //livetv, movies,videos,tvshow
  hostname:any = 'Platform8 '
  searchSubscriber: any;
  owlOption: any;
  is_algolia: boolean = false;

  globalConfig = {
    indexName: JSON.parse(localStorage.getItem('filterTypeData')).search_engine == 'algolia' ? JSON.parse(localStorage.getItem('filterTypeData')).search_settings.collection : JSON.parse(localStorage.getItem('filterTypeData')).search_settings.collection,
    searchClient
  };

  showAllRecordsTab:number =  1;
  allRecords: number = 0;

  showLiveTab:number =  0;
  liveTvRecords: number = 0;

  showMovieTab:number =  0;
  liveMoviesRecords: number = 0;

  showTvTab:number =  0;
  tvshowRecords: number = 0;

  showVideoTab:number =  0;
  videosRecords: number = 0;

  

  //assets_hover:any;

  //allhits: any = { 'tvrecords': '', 'movierecord': '', 'tvshow': '', 'videorecord': '' };
  @ViewChild('totalAllRecordData') totalAllRecordData: ElementRef;
  @ViewChild('totalLiveTvRecordData') totalLiveTvRecordData: ElementRef;
  @ViewChild('totalMoviesRecordData') totalMoviesRecordData: ElementRef;
  @ViewChild('totalTvShowRecordData') totalTvShowRecordData: ElementRef;
  @ViewChild('totalVideosRecordData') totalVideosRecordData: ElementRef;


  @ViewChild('totalAllRecord') totalAllRecord: ElementRef;
  @ViewChild('totalLiveTvRecord') totalLiveTvRecord: ElementRef;
  @ViewChild('totalMoviesRecord') totalMoviesRecord: ElementRef;
  @ViewChild('totalVideosRecord') totalVideosRecord: ElementRef;
  @ViewChild('totalTvShowRecord') totalTvShowRecord: ElementRef;


  // @ViewChild('livetvTabActiveClick') livetvTabActiveClick: ElementRef;
  // @ViewChild('liveMoviebActiveClick') liveMoviebActiveClick: ElementRef;
  // @ViewChild('livetvshowTabActiveClick') livetvshowTabActiveClick: ElementRef;
  // @ViewChild('liveVideoTabActiveClick') liveVideoTabActiveClick: ElementRef;

  searchQuery: string = "";//,hitsPerPage:10
  catlouge = CatlougeTypeConstants.catlogue;
  isParentalControlEnabled;
  searchTimeout: any = undefined;

  userInfo:any;
  playListId:string;
  @ViewChild('addToWatchListSearchSuccess') addToWatchListSuccess;
  @ViewChild('itemAddedIntoWatch') itemAddedIntoWach: ElementRef;
  error_code:string = '';
  playListAvailable: boolean;
  playlist: any;
  tempAsset_id: any;
  isCloudFrontType: boolean = true;
  notFoundImg:any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private searchSerarvice: SearchService,
    private pageTitleService: PageTitleService,
    public commonService: CommonService,
    public UserService:UserService,
    public playListService:PlayListService,
    private modalService:NgbModal,
    private googleEvent: EventTrackingService
  ) { }

  ngOnInit(): void {
    
    if (JSON.parse(localStorage.getItem('filterTypeData')).search_engine != undefined && JSON.parse(localStorage.getItem('filterTypeData')).search_engine == 'algolia') {
      this.is_algolia = true
    }

    this.notFoundImg ="landscape";
    if(this.commonService.filterType ==1){
      this.isCloudFrontType = true ;
    }else{
      this.isCloudFrontType = false;
    }

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if(this.userInfo){
      this.playListId = localStorage.getItem('playListId');
    }
    this.pageTitleService.setTitle(`${this.hostname} | Search`);
    this.getWatchList();
    this.owlOption = customOptionsForFourMiniSlider;
    this.searchSubscriber = this.activatedRoute.queryParams.subscribe(params => {
      this.searchQuery = '';
      this.searchQuery = params['q'];
      this.totalAllRecords();
      this.totalLiveTvRecords();
      this.totalMoviesRecords();
      this.totalVideosRecords();
      this.totalTvShowRecords();

      if(this.searchQuery != undefined)
      this.pageTitleService.setTitle(`${this.hostname} | Search - ${this.searchQuery}`);
      else
      this.pageTitleService.setTitle(`${this.hostname} | Search`);

    });
  }

  get allsearchParameters() {
    
    this.isParentalControlEnabled = this.searchSerarvice.parentalControlStatus();

    if (JSON.parse(localStorage.getItem('filterTypeData')).search_engine != undefined && JSON.parse(localStorage.getItem('filterTypeData')).search_engine == 'algolia') {
      if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
        return {
          query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge
        }
      }

      return {
        query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge + ' AND ' + 'is_adult:no'
      }
    } else {
      if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
        return {
          query: this.searchQuery
        }
      }

      return {
        query: this.searchQuery, 'filters': 'is_adult:no'
      }
    }
  }

  
  get moviesearchParameters() {

    this.isParentalControlEnabled = this.searchSerarvice.parentalControlStatus();

    if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
      return {
        query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge+ ' AND ' + 'type:movie'
      }
    }

    return {
      query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge + ' AND ' + 'is_adult:no'+ ' AND ' + 'type:movie'
    }
  }
  get tvshowsearchParameters() {

    this.isParentalControlEnabled = this.searchSerarvice.parentalControlStatus();

    if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
      return {
        query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge+ ' AND ' + 'type:tvshow'
      }
    }

    return {
      query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge + ' AND ' + 'is_adult:no'+ ' AND ' + 'type:tvshow'
    }
  }
  get videosearchParameters() {

    this.isParentalControlEnabled = this.searchSerarvice.parentalControlStatus();

    if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
      return {
        query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge+ ' AND ' + 'type:video'
      }
    }

    return {
      query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge + ' AND ' + 'is_adult:no'+ ' AND ' + 'type:video'
    }
  }
  get livetvsearchParameters() {

    this.isParentalControlEnabled = this.searchSerarvice.parentalControlStatus();

    if (this.isParentalControlEnabled == null || this.isParentalControlEnabled == '0') {
      return {
        query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge+ ' AND ' + 'type:live'
      }
    }

    return {
      query: this.searchQuery, 'filters': 'catlogue:' + this.catlouge + ' AND ' + 'is_adult:no'+ ' AND ' + 'type:live'
    }
  }

  totalLiveTvRecords() {
    setTimeout(() => {
      if (this.totalLiveTvRecord == undefined) {
        this.liveTvRecords = 0;
        return;
      }
      if (this.totalLiveTvRecord.nativeElement.value != 'undefined')
        this.liveTvRecords = this.totalLiveTvRecord.nativeElement.value;
    }, 1000);
  }

  totalMoviesRecords() {
    setTimeout(() => {
      if (this.totalMoviesRecord == undefined) {
        this.liveMoviesRecords = 0;
        return;
      }
      if (this.totalMoviesRecord.nativeElement.value != 'undefined')
        this.liveMoviesRecords = this.totalMoviesRecord.nativeElement.value;

    }, 1000);
  }

  totalTvShowRecords() {
    setTimeout(() => {
      if (this.totalTvShowRecord == undefined) {
        this.tvshowRecords = 0;
        return;
      }
      if (this.totalTvShowRecord.nativeElement.value != 'undefined') {
        this.tvshowRecords = this.totalTvShowRecord.nativeElement.value;

      } else {
        this.tvshowRecords = 0;
      }
    }, 1000);
  }

  totalAllRecords() {
    console.log('search Running');
    setTimeout(() => {
      if (this.totalAllRecord == undefined) {
        this.allRecords = 0;
        return;
      }
      if (this.totalAllRecord.nativeElement.value != 'undefined')
        this.allRecords = this.totalAllRecord.nativeElement.value;
        this.check_into_watchlist(this.allRecords);

      }, 1000);
  }
  totalVideosRecords() {
    setTimeout(() => {
      if (this.totalVideosRecord == undefined) {
        this.videosRecords = 0;
        return;
      }
      if (this.totalVideosRecord.nativeElement.value != 'undefined')
        this.videosRecords = this.totalVideosRecord.nativeElement.value;

    }, 1000);
  }

  jsonString(data) {
    return JSON.stringify(data);
  }

  // changeTab(tab) {
  //   switch (tab) {
  //     case 'livetv':
  //       this.livetvTabActiveClick.nativeElement.click();
  //       break;
  //     case 'movies':
  //       this.liveMoviebActiveClick.nativeElement.click();
  //       break;
  //     case 'tvshows':
  //       this.livetvshowTabActiveClick.nativeElement.click();
  //       break;
  //     case 'videos':
  //       this.liveVideoTabActiveClick.nativeElement.click();
  //       break;

  //   }
  // }

  goto(tempQueryStr: any, content) {

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    

    if(this.userInfo == undefined || Object.keys(this.userInfo).length == 0){
      this.commonService.setBeforeLoginState(tempQueryStr);
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      }, (reason) => {
      });

      return false;
    }


    let data = {
      "code": tempQueryStr.code,
      "catlogue": tempQueryStr.catlogue[0],
      "plateform": "web",
      "name": tempQueryStr.name,
      "charge_code": tempQueryStr.charge_code ? tempQueryStr.charge_code : '',
      "genre": tempQueryStr.genre ? tempQueryStr.genre : '',
      "type": tempQueryStr.type ? tempQueryStr.type : ''
    }
    data.type = data.type =='live'?'livetv':data.type;
    this.commonService.goToDetail(data, 'Screen_Search');
    // this.router.navigate(['detail-episode'], { queryParams: data })
  }
  ngOnDistroy() {
    this.searchSubscriber.unsubscribe();
  }

  strToLower(str) {
    if (str)
      return str.toLowerCase();
    else
      return 'na';
  }

  AddToWatchList(e, item, content){
    e.stopPropagation();
    if(Object.keys(this.userInfo.result).length > 0) {

      let userUnique = '';
      if (this.userInfo.result.register_by == "mobile") {
        userUnique = this.userInfo.result.mobile;
      } else {
        userUnique = this.userInfo.result.emailaddress;
      }

      this.googleEvent.eventEmitter("Watchlist" , "Watchlist" , userUnique+'-'+item.code+'-'+item.name,0,userUnique , userUnique+'-'+item.code+'-'+item.name);

      let addToplayListParams = {
        playlistid:this.playListId?this.playListId:'',
        catlogue:CatlougeTypeConstants.catlogue,
        plateform:"web",
        playingtype:"Video",
        assetId:item.asset_mongo_id?item.asset_mongo_id:item._id,
        assetType:item.type?item.type:'',
        action:"saveAssetInPlaylist",
      }
  
      console.log(addToplayListParams);
      let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(addToplayListParams);    
      console.log(item.asset_mongo_id?item.asset_mongo_id:item._id);
      if((item.asset_mongo_id?item.asset_mongo_id:item._id) != undefined){
            this.playListService.addToPlayList(urlEncodedToplayListParams).subscribe((data)=>{
              this.error_code = data.error_code;
                console.log(this.error_code);
                if(data.error_code == '200'){
                  // this.itemAddedIntoWach.nativeElement.innerHTML = `Item Successfully added ${this.setPlayListItem?.name} into your Watchlist.`;  
                  document.getElementById('itemAddSuccessMsg').innerHTML= `Successfully added to Watchlist.`; 
                  this.addToWatchListSuccess.nativeElement.click(); 

                }
                if(data.error_code == '209'){
                  // this.itemAddedIntoWach.nativeElement.innerHTML = 'This item already exist in your Watchlist ';  
                  document.getElementById('itemAddSuccessMsg').innerHTML=`Remove from Watchlist.`;  
                  this.addToWatchListSuccess.nativeElement.click();
                  this.deleteFromWatchList(item)
                }
                
                setTimeout(()=>{
                  document.getElementById('closeAddToWatchListFromSearch').click();
                }, 1500);
    

              console.log(this.error_code)
            // this.toastr.success('Success!', '', { timeOut: 2000 });
              // this.closeAddtoWatchListModal.nativeElement.click();
  
          });
      } else {
        // this.closeAddtoWatchListModal.nativeElement.click();
      }
    } else {
      // console.log(content);
      this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      }, (reason) => {
      });
    }
  }


  getWatchList(){

    this.playListId = localStorage.getItem('playListId');
    let temp = {
      playlistid:this.playListId?this.playListId:'',
      catlogue:CatlougeTypeConstants.catlogue,
      plateform:"web",
      action:"getPlayListAsset",
    }

    let urlEncodedPlayListAssetsParams = this.UserService.convertoJSONToUrlEncoded(temp);    
    return this.playListService.getPlaylist(urlEncodedPlayListAssetsParams).subscribe(data => {
      if(data&&data.result&&Array.isArray(data.result)) {
        if(Array.isArray(data.result)){
          this.playListAvailable = true;
          this.playlist = data.result;
          // this.check_into_watchlist()
          return this.playlist;
        } 
      }
    });

  }


  check_into_watchlist(item){
    console.log(item);
    // this.viewAll.result[0].assets.forEach((tempAsset,tempIndex) => {

    //   this.tempAsset_id = item.asset_mongo_id?item.asset_mongo_id:item._id
        

    //     if( this.playlist.findIndex(tempObj => tempObj._id == this.tempAsset_id ) != -1 ){
    //       this.viewAll.result[0].assets[tempIndex].is_watchlist_added = true;
    //     } else {
    //       this.viewAll.result[0].assets[tempIndex].is_watchlist_added = false;
    //     }
    //   });
}


  deleteFromWatchList(item){

    let deletePlayListAssets = {
    playlistid:this.playListId,
    assetId:item.asset_mongo_id?item.asset_mongo_id:item._id,
    catlogue:CatlougeTypeConstants.catlogue,
    plateform:"web",
    // playlist_asset_id:item._id,
    action:"deleteAssetFromPlaylist"
    }
  
    let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(deletePlayListAssets); 
    this.playListService.deleteFromPlayList(urlEncodedToplayListParams).subscribe((data)=>{
      // document.getElementById('itemAddSuccessMsg').innerHTML=`Removed From watchlist`;  
  
      // console.log(data);
    // let index = this.playlist.findIndex(playItem => playItem._id === item._id);
    });
  
    }
  

  // item_hover_enter(){
  //   console.log("item hover enter");
  //   this.assets_hover= true;
  //       }
    
  // item_hover_leave(){
  //   console.log("item hover leave");
  //   this.assets_hover= false;
  // }

  // assets_hover1:boolean= false;
  // assets_hover2:boolean= false;
  // assets_hover3:boolean= false;
  // assets_hover4:boolean= false;

  // item_hover_enter1(){
  //   console.log("item hover enter");
  //   this.assets_hover1= true;
  //       }
    
  // item_hover_leave1(){
  //   console.log("item hover leave");
  //   this.assets_hover1= false;
  // }

  // item_hover_enter2(){
  //   console.log("item hover enter");
  //   this.assets_hover2= true;
  //       }
    
  // item_hover_leave2(){
  //   console.log("item hover leave");
  //   this.assets_hover2= false;
  // }

  // item_hover_enter3(){
  //   console.log("item hover enter");
  //   this.assets_hover3= true;
  //       }
    
  // item_hover_leave3(){
  //   console.log("item hover leave");
  //   this.assets_hover3= false;
  // }

  // item_hover_enter4(){
  //   console.log("item hover enter");
  //   this.assets_hover4= true;
  //       }
    
  // item_hover_leave4(){
  //   console.log("item hover leave");
  //   this.assets_hover4= false;
  // }

}
