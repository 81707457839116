import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from './account.service';
import { UserService } from 'src/app/core';
import { Router } from '@angular/router';
import * as moment from 'moment'; // add this 1 of 4
import { CatlougeTypeConstants, resendOtpTimer } from "src/app/core/constants";
import { SearchService } from '../search/search.service';
import { CommonService } from 'src/app/core/services/common.service';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { PageTitleService } from 'src/app/services/title/page-title.service';
import { environment } from 'src/environments/environment';
import { range } from 'rxjs';
import * as e from 'express';
import { concat } from 'rxjs-compat/operator/concat';
declare var $: any;
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  addNameForm: FormGroup;
  @ViewChild('closebutton') closebutton;
  @ViewChild('add_namesuccess') openNameSuccess;
  name_error: string = '';
  first_name_text = 'Edit your name';
  first_name_button_text = 'Edit';
  canCancel: boolean = false;

  addMobileForm: FormGroup;
  addMobileOTPForm: FormGroup;
  @ViewChild('closeMobilebutton') closeMobilebutton;
  @ViewChild('showOTPModal') showOTPModal;
  @ViewChild('closeOTPModal') closeOTPModal;
  @ViewChild('openMobileNumberModal') openMobileNumberModal;
  @ViewChild('showMobileSuccess') showMobileSuccess;
  @ViewChild('cancel_subscription_confirmation') cancel_subscription_confirmation;
  @ViewChild('closeProfileForm') closeProfileForm;

  mobile_error: string = '';
  mobile_name_text = '';
  mobile_name_button_text = 'Change';
  other_contact_button_text = 'Add';
  catlogue = CatlougeTypeConstants.catlogue;
  otp_success_msg: string = '';
  registredMobileNumber = '(Mobile number not provided)';
  showregistredMobileNumber: any
  mobile_otp_error: any = '';
  email_otp_error: any = '';

  addEmailForm: FormGroup;
  addEmailOTPForm: FormGroup;
  @ViewChild('closeEmailbutton') closeEmailbutton;
  @ViewChild('showEmailOTPModal') showEmailOTPModal;
  @ViewChild('closeEmailOTPModal') closeEmailOTPModal;
  @ViewChild('openEmailModal') openEmailModal;
  @ViewChild('showEmailSuccess') showEmailSuccess;

  addOptionalEmailForm: FormGroup;
  changeOptionalEmailForm: FormGroup;
  addOptionalEmailOTPForm: FormGroup;
  @ViewChild('showOptionalEmailOTPModal') showOptionalEmailOTPModal;
  @ViewChild('closeOptionalEmailbutton') closeOptionalEmailbutton;
  @ViewChild('closeOptionalEmailChangebutton') closeOptionalEmailChangebutton;
  @ViewChild('closeVerifyOptionalEmailOTPModal') closeVerifyOptionalEmailOTPModal;
  @ViewChild('showOptionalEmailSuccess') showOptionalEmailSuccess;
  @ViewChild('closeSuccessOptionalEmailOTPModal') closeSuccessOptionalEmailOTPModal;


  addOptionalMobileForm: FormGroup;
  changeOptionalMobileForm: FormGroup;
  addOptionalMobileOTPForm: FormGroup;
  @ViewChild('showOptionalMobileOTPModal') showOptionalMobileOTPModal;
  @ViewChild('closeOptionalMobilebutton') closeOptionalMobilebutton;
  @ViewChild('closeOptionalMobileChangebutton') closeOptionalMobileChangebutton;
  @ViewChild('closeVerifyOptionalMobileOTPModal') closeVerifyOptionalMobileOTPModal;
  @ViewChild('showOptionalMobileSuccess') showOptionalMobileSuccess;



  email_error: string = '';
  email_name_text = '';
  email_name_button_text = 'Change';

  email_otp_success_msg: string = '';
  registredEmail = '(Email ID not added)';
  public d = parseInt(new Date().getFullYear() + "");
  public yearList = range(1950, this.d);

  changePasswordForm: FormGroup;
  @ViewChild('changePasswordClose') changePasswordClose;
  @ViewChild('changePasswordSuccess') changePasswordSuccess;
  change_password_error: string = '';

  @ViewChild('oldpass') oldpass;
  @ViewChild('oldEyeEvent') oldEyeEvent;
  @ViewChild('npass') npass;
  @ViewChild('newEyeEvent') newEyeEvent;
  @ViewChild('confirmPass') confirmPass;
  @ViewChild('confirmEyeEvent') confirmEyeEvent;


  registered_mobile = '';
  registered_email = '';

  parental_control = '0';
  parental_control_success_text = '';
  registeredBy = 'mobile';
  isPlanActive: boolean = false;
  packDetail: any = []; //{ expiryDate: '' };

  @ViewChild('showPackSuccess') showPackSuccess;
  @ViewChild('closeRemoveSubscriptionModal') closeRemoveSubscriptionModal;
  remove_subscription_error: string = '';

  addPromoForm: FormGroup;
  @ViewChild('closePromoButton') closePromoButton;
  @ViewChild('add_promocodesuccess') add_promocodesuccess;
  promocode_error: string = '';

  addParentalForm: FormGroup;
  @ViewChild('closeParentalForm') closeParentalForm;
  @ViewChild('showParentalSuccess') showParentalSuccess;
  parental_otp_error: string = '';
  parental_otp_success: string = '';
  userInfo: any;


  // error messages 

  wrongOldPwd: string = '';
  wrongNewPwd: string = '';
  wrongCnfrmPwd: string = '';
  mobile_number_error: string = '';
  packExpiryDate: any = '';

  otpIntervalTimer: any = resendOtpTimer.otpTimer;
  otpInt: any = resendOtpTimer.otpTimer;
  maxOtpAttempt = 5;
  isMobileOtpResend: boolean = false;

  emailotpInt: any = resendOtpTimer.otpTimer;
  maxEmailOtpAttempt = 5;
  isEmailOtpResend: boolean = false;

  parentotpInt: any = resendOtpTimer.otpTimer;
  maxParentOtpAttempt = 5;
  isParentOtpResend: boolean = false;
  error_code: any;

  canBuy: boolean = true;
  cancelPack: any;
  platform: any = '1';
  cancelPackDetail: any = {};
  frsitTwoDigit: string;
  mobile_error_string: any;

  errorCode: any;
  tellUsAllFieldsArray: any = [];
  updateProfileForm: any;
  tellUsValueWiyhAllFieldsArray: any = [];
  errors: { errors: {}; };
  UpdateSuccessMsg: boolean = false;
  isTellUsSubmitted: boolean = false;
  public newArray: any = [];
  public tellUsValueWiyhAllFieldsArray1: any;

  secondSring: any;
  fristSring: any;
  mobile_error_contact: string;
  otherContact: any;
  otherContactVerification: any;
  optional_email_name_text: any;
  optional_error_code: any;

  otpOptionalInt: any = resendOtpTimer.otpTimer;
  emailOptionalotpInt: any = resendOtpTimer.otpTimer;
  optional_email_otp_success_msg: any = '';
  optional_email_error_code: any;
  showTimer: any = resendOtpTimer.otpTimer;
  enableResendOTP: boolean = false;
  optional_mobile_error: string;
  maxDate: any;
  tellUsPageShowAndHide: boolean;
  public margeArray: any;
  public output: any;
  existing: any;
  public fullD: any;
  public timeStmp: any;
  public flag: any;

  constructor(
    public formBuilder: FormBuilder,
    private apiService: AccountService,
    private userService: UserService,
    public route: Router,
    public commonService: CommonService,
    private searchSerarvice: SearchService,
    private pageTitleService: PageTitleService,
    private googleEvent: EventTrackingService,
  ) {
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    this.addNameForm = this.formBuilder.group({
      'username': ['', [Validators.required, Validators.pattern(nonWhitespaceRegExp)]]
    });

    this.addEmailForm = this.formBuilder.group({
      'email': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]]
    });
    this.addEmailOTPForm = this.formBuilder.group({
      'verify_email_otp': ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), this.validateNumber.bind(this)]]
    });
    this.addOptionalEmailOTPForm = this.formBuilder.group({
      'verify_optional_email_otp': ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), this.validateNumber.bind(this)]]
    });

    this.addMobileForm = this.formBuilder.group({
      'mobile_number': ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), this.validateNumber.bind(this)]]
    });
    this.addMobileOTPForm = this.formBuilder.group({
      'verify_otp': ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), this.validateNumber.bind(this)]]
    });

    this.addOptionalEmailForm = this.formBuilder.group({
      'optional_email': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]]
    });
    this.changeOptionalEmailForm = this.formBuilder.group({
      'change_optional_email': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]]
    });
    this.addOptionalMobileForm = this.formBuilder.group({
      'add_optional_mobile': ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), this.validateNumber.bind(this)]]
    });
    this.changeOptionalMobileForm = this.formBuilder.group({
      'change_optional_mobile': ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), this.validateNumber.bind(this)]]
    });



    this.changePasswordForm = this.formBuilder.group({
      'old_pass': ['', [Validators.required, Validators.minLength(8), Validators.pattern(nonWhitespaceRegExp)]],
      'new_pass': ['', [Validators.required, Validators.minLength(8), Validators.pattern(nonWhitespaceRegExp)]],
      'confirm_pass': ['', [Validators.required, Validators.minLength(8), Validators.pattern(nonWhitespaceRegExp)]]
    }, { validator: this.matchingPasswords('new_pass', 'confirm_pass') });

    this.addPromoForm = this.formBuilder.group({
      'promocode': ['', Validators.required]
    });

    this.addParentalForm = this.formBuilder.group({
      'parental_enter_otp': ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), this.validateNumber.bind(this)]]
    });

    // this.pageTitleService.setTitle('My account');
    this.userService.updateSelectedMenu('My account');

    this.updateProfileForm = this.formBuilder.group({});
  }
  validateNumber(control: FormControl): { [s: string]: boolean } {

    //revised to reflect null as an acceptable value 
    if (control.value === null) return null;

    // check to see if the control value is no a number
    if (isNaN(control.value)) {
      return { 'NaN': true };
    }

    return null;
  }


  get mobileNumberControl() { return this.addMobileForm.get('mobile_number'); }

  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    // TODO maybe use this https://github.com/yuyang041060120/ng2-validation#notequalto-1
    return (group: FormGroup): { [key: string]: any } => {
      let password = group.controls[passwordKey];
      let confirmPassword = group.controls[confirmPasswordKey];

      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: true
        };
      }
    }
  }


  // confirm new password validator
  private passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
      this.changePasswordForm &&
      (control.value !== this.changePasswordForm.controls.new_pass.value)
    ) {
      return { passwordNotMatch: true };
    }
    return null;
  }

  resetChangePasswordForm() {
    this.changePasswordForm.reset();
  }

  ngOnInit(): void {
    this.maxDate = new Date();
    if (localStorage.getItem('isAuthenticated') == undefined) {
      this.route.navigate(['/']);
    } else {
      this.profileDetails();
      this.getSubscriptionDetails();
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    }


  }
  get tellFormControls() { return this.updateProfileForm.controls; }
  profileDetails() {
    let da = "";
    this.userService.getTellUsFields(da).subscribe((data1: any) => {
      this.errorCode = data1['error_code'];

      if (this.errorCode == "200") {

        this.tellUsAllFieldsArray = data1['result']['additional_field_data'];
        this.tellUsValueWiyhAllFieldsArray = data1['result']['key_value_data'];
        if (this.tellUsAllFieldsArray.length > 0) {
          this.tellUsPageShowAndHide = true;
        } else {
          this.tellUsPageShowAndHide = false;
        }
        try {
          this.output = this.tellUsAllFieldsArray.map((item1) => {
            this.existing = this.tellUsValueWiyhAllFieldsArray.find(({ slug }) => {
              return item1.slug == slug;
            });

            if (this.existing) {
              return { ...item1, value: this.existing.value }
            } else {
              return { ...item1, value: '' };
            }
          });
          this.output.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
        }
        catch (e) {

        }
        // alert(JSON.stringify(this.output));

        this.tellUsValueWiyhAllFieldsArray1 = {};
        data1['result']['additional_field_data'].map((i, v) => {
          this.tellUsValueWiyhAllFieldsArray1[i.slug] = i.title
        })
        // this.tellUsAllFieldsArray.forEach((item: any, index: any) => {
        //   let found = this.tellUsValueWiyhAllFieldsArray.find(e => e.slug === item.slug);
        //   if (item.is_required == 1) {
        //     if (item.type == 'input_calender') {
        //       let a = found.value;
        //       var fullD = new Date(Number(a)*1000);
        //       this.updateProfileForm.addControl('mycustomDate', new FormControl(fullD ?? '', [Validators.required]))
        //     } else {
        //       this.updateProfileForm.addControl(item.slug, new FormControl(found.value ?? '', [Validators.required]))
        //     }
        //   } else {
        //     this.updateProfileForm.addControl(item.slug, new FormControl(found.value ?? ''))
        //   }
        // });

        this.output.forEach((item1: any, index: any) => {
          if (item1.is_required == 1) {
            if (item1.type == 'input_calender') {
              let a = item1.value;
              if (a) {
                this.fullD = new Date(Number(a) * 1000);
              } else {
                this.fullD = "";
              }
              this.updateProfileForm.addControl('mycustomDate' + index, new FormControl(this.fullD ?? '', [Validators.required]))
            } else {
              this.updateProfileForm.addControl(item1.slug, new FormControl((item1.value).trim() ?? '', [Validators.required]))
            }
          } else {
            if (item1.type == 'input_calender') {
              let a = item1.value;
              if (a) {
                this.fullD = new Date(Number(a) * 1000);
              } else {
                this.fullD = "";
              }
              this.updateProfileForm.addControl('mycustomDate' + index, new FormControl(this.fullD ?? ''))
            } else {
              this.updateProfileForm.addControl(item1.slug, new FormControl((item1.value).trim() ?? ''));
            }
          }

        });
      }

    }, (err: any) => {
      this.name_error = 'Something went wrong!! Please try again after some time.';
    });

    this.apiService.getData('signupnexg/profile')
      .subscribe((data: any) => {

        if (Object.keys(data.result).length > 0) {
          this.registeredBy = data.result.register_by;
          this.first_name_text = data.result.first_name;
          this.registredEmail = data.result.emailaddress;
          this.registredMobileNumber = data.result.mobile;
          this.otherContact = data.result.other_contact;
          this.otherContactVerification = data.result.other_contact_verification
          this.frsitTwoDigit = this.registredMobileNumber.substring(0, 2);
          this.showregistredMobileNumber = this.frsitTwoDigit + 'XXXXXX' + this.registredMobileNumber.substring(this.registredMobileNumber.length - 2, this.registredMobileNumber.length);
          this.parental_control = data.result.parental_control;
          this.searchSerarvice.setParentalControl(data.result.parental_control);


          if (this.first_name_text == '') {
            this.first_name_button_text = 'Add';
            this.first_name_text = '(Name not added)';
          }
          if (this.registredEmail == '') {
            this.email_name_button_text = 'Add';
            this.registredEmail = '(Email ID not added)';
          }

        }

      },
        error => {
          this.name_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }



  UpdateProfileSetting() {
    this.errors = { errors: {} };
    this.isTellUsSubmitted = true;
    console.log(this.updateProfileForm);
    if (this.updateProfileForm.invalid) {
      return;
    }

    this.tellUsAllFieldsArray.forEach((item: any, index: any) => {
      if (item.type == 'input_calender') {
        let newDate = this.updateProfileForm.controls["mycustomDate" + index].value;
        if (newDate) {
          this.timeStmp = newDate.getTime() / 1000;
        } else {
          this.timeStmp = "";
        }
        this.updateProfileForm.addControl(item.slug, new FormControl(this.timeStmp ?? ''))
      }
    });


    const credentials1 = this.updateProfileForm.value;
    this.tellUsAllFieldsArray.forEach((item1: any, index1: any) => {
      if (item1.type == 'input_calender') {
        delete credentials1["mycustomDate" + index1];
      }
    });

    this.flag = 1;
    Object.keys(credentials1).forEach((value: any) => {
      if (credentials1[value] != '' && credentials1[value] != undefined && credentials1[value] != null) {
        this.flag = 0
      }

    });

    this.updateProfileForm.addControl('is_additional_profile_field_skipped', new FormControl(this.flag ?? ''));

    const credentials = this.updateProfileForm.value;
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
    this.userService.getTellUsFields(urlEncodedBodyData).subscribe(data => {
      if (data['error_code'] == "200") {
        this.UpdateSuccessMsg = true;
        setTimeout(() => {
          this.closeProfileForm.nativeElement.click();
          window.location.reload();
        }, 3000);
      }

    }, err => {
      this.errors = err;
    });

  }

  closeBox() {
    let userUnique = 'NA';
    if (this.registeredBy == 'mobile') {
      userUnique = this.registredMobileNumber;
    }
    if (this.registeredBy != 'mobile') {
      userUnique = this.registredEmail;
    }

    // this.googleEvent.eventEmitter("No", "Subscription Cancelled", userUnique + ' - My account');
    // var user=this.mobileNumber?this.mobileNumber:this.emailId;
    this.googleEvent.eventEmitter('my_account_subscription', 'my_account_subscription', 'Click on NO', 0, userUnique, 'Cancel Subscription confirmation popup');

  }
  strToLower(str: string) {
    if (str)
      return str.toLowerCase().trim();
    else
      return str;
  }


  open(pageName?: string, obj?: any) {


    this.cancelPackDetail = obj;
    if (pageName == 'namePop') {
      // console.log('add_to_cart');
      this.googleEvent.eventEmitter('my_account_profile', 'my_account_profile', 'Open Update name popup', 0, this.registredMobileNumber ? this.registredMobileNumber : this.registredEmail, this.registredMobileNumber ? this.registredMobileNumber : this.registredEmail + ' - ' + 'Edit Name');
      // this.googleEvent.eventEmitter("Edit Name", "My Account", "Open Update name popup");
    }

    if (pageName == 'redeem_code') {
      this.googleEvent.eventEmitter('my_account_subscription', 'my_account_subscription', 'Open redeem code popup', 0, this.registredMobileNumber ? this.registredMobileNumber : this.registredEmail, this.registredMobileNumber ? this.registredMobileNumber : this.registredEmail + ' _ ' + 'Redeem');
      //this.googleEvent.eventEmitter("Redeem", "My Account", "Open redeem code popup");
    }
    if (pageName == 'cancel_subscription') {
      this.googleEvent.eventEmitter('my_account_subscription', 'my_account_subscription', 'Open Cancel Subscription confirmation popup', 0, this.registredMobileNumber ? this.registredMobileNumber : this.registredEmail, this.registredMobileNumber ? this.registredMobileNumber : this.registredEmail + ' - ' + 'Cancel Subscription');
      // this.googleEvent.eventEmitter("Cancel Subscription", "My Account", "Open Cancel Subscription confirmation popup");

      this.cancelPack = obj;
    }

    if (obj.applicable_for.findIndex(pt => pt == this.platform) != -1) {
      this.canCancel = true;
    }
    else {
      this.canCancel = false;
    }
    this.name_error = '';
    this.mobile_error = '';
    this.otp_success_msg = '';

    this.email_error = '';
    this.email_otp_success_msg = '';

    this.remove_subscription_error = '';
    this.promocode_error = '';

    this.addEmailForm.reset();
    this.addMobileForm.reset();
    this.addOptionalEmailForm.reset();
    this.addOptionalMobileForm.reset();
    this.changeOptionalMobileForm.reset();

  }


  updateName() {
    this.name_error = '';
    if (this.addNameForm.invalid) {
      return false;
    }
    let username = this.addNameForm.controls.username.value;
    username = username.trim();
    if (username.length <= 0) {
      this.name_error = 'Name is required.';
      return false;
    }

    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'first_name': username.trim() });
    this.apiService.postData('updateprofile', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.error_code = data['error_code'];
        if (data.result) {

          // $('body').addClass('modal-opena');
          this.closebutton.nativeElement.click(); // close the name field modal
          this.openNameSuccess.nativeElement.click(); // show success

          this.first_name_text = this.addNameForm.controls.username.value;
          this.addNameForm.reset();

          // code is updated by Shailendra
          this.userInfo.result.first_name = this.first_name_text;
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          this.name_error = data.error_string;
        }

      },
        error => {
          this.name_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }

  sentEmailOTP() {
    if (this.addEmailForm.invalid) {
      return false;
    }
    this.isEmailOtpResend = false;
    this.email_error = '';
    this.email_otp_success_msg = '';
    let email = this.addEmailForm.controls.email.value;
    let sessionId = localStorage.getItem('sessionId');
    this.resendEmailOtpTimer();
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'catlogue': this.catlogue, 'emailaddress': email, sessionId: sessionId });
    this.apiService.postData('signupnexg/resetpass', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.error_code = data['error_code'];

        if (Object.keys(data.result).length > 0) {
          this.email_error = data.error_string;
          this.email_otp_error = data.error_string;
          // if(data.error_string.includes("Contact")){
          //   this.email_error = '';
          //   this.email_error_string = data.error_string.split("Contact"); 
          //   this.fristSring = this.mobile_error_string[0];
          //   this.secondSring = this.mobile_error_string[1];
          // }else{
          //   this.fristSring ='';
          //   this.email_error = data.error_string;
          // }
          
          if (data.error_code != '429') {
            this.closeEmailbutton.nativeElement.click(); // close the name field modal
            this.showEmailOTPModal.nativeElement.click(); // show otp box
            this.addEmailForm.reset();
            this.email_error = "";
            this.email_name_text = email;
            this.email_otp_error = data.error_string;
            this.email_otp_success_msg = data.error_string;
          }

        } else {
          this.email_error = data.error_string;
          this.email_otp_error = data.error_string;
        }

      },
        error => {
          this.email_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );

  }


  closeEmailBtn() {
    this.addEmailForm.reset();
    // this.addEmailForm.get('email').updateValueAndValidity();
   // this.addEmailForm = this.formBuilder.group({'email': ['']});
    this.email_error = '';
    this.email_otp_success_msg = '';
    this.optional_email_error_code = '';
  }

  sentOptionalEmailOTP(field: any) {

    // if (this.addOptionalEmailForm.invalid || this.addOptionalMobileForm.invalid) {
    //   return false; change_optional_mobile
    // }
    this.optional_email_otp_success_msg = '';
    let other_contact;
    this.isEmailOtpResend = false;
    this.email_error = '';
    this.email_otp_success_msg = '';
    if (field == 'changemobile') {
      other_contact = this.changeOptionalMobileForm.controls.change_optional_mobile.value;
      if (other_contact.toString().length < 10 || other_contact.toString().length > 10) {
        this.optional_mobile_error = 'Mobile number must be at least 10 digit long.';
      }
    }
    if (field == 'addemail') {
      other_contact = this.addOptionalEmailForm.controls.optional_email.value;
    }
    if (field == 'addmobile') {
      other_contact = this.addOptionalMobileForm.controls.add_optional_mobile.value;
      if (other_contact.toString().length < 10 || other_contact.toString().length > 10) {
        this.optional_mobile_error = 'Mobile number must be at least 10 digit long.';
      }
    }
    if (field == 'changeemail') {
      other_contact = this.changeOptionalEmailForm.controls.change_optional_email.value;
    }
    if (other_contact == undefined || other_contact == null || other_contact == '') {
      return false;
    }

    let sessionId = localStorage.getItem('sessionId');

    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'catlogue': this.catlogue, 'other_contact': other_contact, sessionId: sessionId });
    this.apiService.postData('updateprofile', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.optional_mobile_error = '';
        this.optional_email_otp_success_msg = data.error_string;
        this.optional_email_error_code = data.error_code;
        // if(data.error_code != '429'){
        this.resendOptionalEmailOtpTimer();
        // }
        // this.optional_error_code = data['error_code'];
        if (Object.keys(data.result).length > 0) {

          this.email_error = data.error_string;
          this.closeOptionalEmailbutton.nativeElement.click();
          this.showOptionalEmailOTPModal.nativeElement.click(); // show otp box
          this.closeOptionalEmailChangebutton.nativeElement.click();
          this.closeOptionalMobileChangebutton.nativeElement.click();
          this.closeOptionalMobilebutton.nativeElement.click();
          this.profileDetails();
          this.optional_email_name_text = other_contact;
          this.changeOptionalEmailForm.reset();
          this.addOptionalEmailForm.reset();
          this.changeOptionalMobileForm.reset();
          this.addOptionalMobileForm.reset();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
          // this.email_otp_success_msg = data.error_string;
        } else {
          this.email_error = data.error_string;
        }

      },
        error => {
          this.email_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );

  }

  closeOptionalMobileBtn() {
    this.email_error = '';
    this.optional_mobile_error = '';
    this.optional_email_otp_success_msg = '';
    this.optional_email_error_code = '';
    this.addOptionalMobileForm.reset();
  }

  closeOptionalEmailBtn() {
    this.email_error = '';
    this.optional_mobile_error = '';
    this.optional_email_otp_success_msg = '';
    this.optional_email_error_code = '';
    this.addOptionalEmailForm.reset();
  }

  closeOptionalEmailChangeBtn() {
    this.email_error = '';
    this.optional_mobile_error = '';
    this.optional_email_otp_success_msg = '';
    this.optional_email_error_code = '';
    this.changeOptionalEmailForm.reset();
  }

  closeOptionalMobileChangeBtn() {
    this.email_error = '';
    this.optional_mobile_error = '';
    this.optional_email_otp_success_msg = '';
    this.optional_email_error_code = '';
    this.changeOptionalMobileForm.reset();
  }

  setAutoFillOff(e) {

  }
  autosentOptionalEmailOTP() {
    let urlEncodedBodyData = '';
    this.optional_email_otp_success_msg = '';
    this.resendOptionalEmailOtpTimer();
    this.apiService.postData('signupnexg/sendocontactotp', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.optional_email_otp_success_msg = data.error_string;
        this.optional_email_error_code = data.error_code;
        // if(data.error_code != '429'){
        // }
      },
        error => {
          this.optional_email_otp_success_msg = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }



  editEmail() {
    this.addEmailOTPForm.reset();
    this.email_error = '';
    this.email_otp_success_msg = '';
    this.closeEmailOTPModal.nativeElement.click();
    this.openEmailModal.nativeElement.click();
  }
  removeSpace(event) {
    console.log(event.code);

    // let patt = /[0-9]|\./;// /^([0-9])$/;
    // let isNumber = patt.test(this.addMobileForm.controls.mobile_number.value);
  }
  clearEmailError(evt) {

    var charCode = (evt.which) ? evt.which : evt.keyCode;

    if (charCode != 13) { this.email_error = ''; }

    // this.email_error = '';
    // if(isNaN(this.addEmailOTPForm.controls.verify_email_otp.value))
    // this.addEmailOTPForm.controls.verify_email_otp.setValue('');
  }

  resendEmailOTP(activateTimer?: any) {
    this.isEmailOtpResend = false;

    this.email_otp_error = '';
    this.email_otp_success_msg = '';

    if (this.email_name_text == '') {

      this.email_error = "Something went wrong. Please try after some time."
      return false;
    }
    console.log('resendEmailOTP');
    let sessionId = localStorage.getItem('sessionId');
    this.resendEmailOtpTimer();
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'catlogue': this.catlogue, 'emailaddress': this.email_name_text, sessionId: sessionId });
    this.apiService.postData('signupnexg/resetpass', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.error_code = data['error_code'];
        this.email_otp_error = data.error_string;

        if (Object.keys(data.result).length > 0) {
          this.email_otp_success_msg = data.error_string;
          this.email_otp_error = data.error_string;

          // if (activateTimer == 1) {
          //   console.log('activateTimer');
          //   this.resendEmailOtpTimer();
          //   this.isEmailOtpResend = true;
          // }
        } else {
          this.email_otp_error = data.error_string;
        }

      },
        error => {
          this.email_otp_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );

  }


  verifyOptionalEmailOTP() {


    if (this.addOptionalEmailOTPForm.invalid) {
      return false;
    }
    this.email_error = '';
    this.optional_email_otp_success_msg = '';

    let email = this.email_name_text;
    let otp = this.addOptionalEmailOTPForm.controls.verify_optional_email_otp.value;
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'catlogue': this.catlogue, 'other_contact': this.otherContact, otp: otp });
    this.apiService.postData('signupnexg/verifyocontactotp', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.optional_email_otp_success_msg = data.error_string;
        this.optional_email_error_code = data.error_code;
        // if(data.error_code != '429'){
        this.resendOptionalEmailOtpTimer();
        // } 
        this.error_code = data['error_code'];
        if (Object.keys(data.result).length > 0) {
          this.addOptionalEmailOTPForm.reset();
          // this.updatePrfileEmail({ 'catlogue': this.catlogue, 'other_contact': this.otherContact, otp: otp });
          this.profileDetails();
          this.getSubscriptionDetails();
          this.closeEmailOTPModal.nativeElement.click();
          this.closeVerifyOptionalEmailOTPModal.nativeElement.click();
          this.showOptionalEmailSuccess.nativeElement.click();
          setTimeout(() => {
            window.location.reload();
            this.closeSuccessOptionalEmailOTPModal.nativeElement.click();
          }, 3000);
        } else {
          this.optional_email_otp_success_msg = data.error_string;
        }

      },
        error => {
          this.email_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }


  closeVerifyOptionalEmailBtn() {
    this.email_error = '';
    this.optional_email_otp_success_msg = '';
    this.error_code = '';
    this.optional_email_otp_success_msg = '';
    this.optional_email_error_code = '';
    this.addOptionalEmailOTPForm.reset();
  }

  verifyEmailOTP() {


    if (this.addEmailOTPForm.invalid) {
      return false;
    }
    this.email_otp_error = '';
    this.email_otp_success_msg = '';

    let email = this.email_name_text;
    let otp = this.addEmailOTPForm.controls.verify_email_otp.value;

    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'catlogue': this.catlogue, 'emailaddress': email, otp: otp });
    this.apiService.postData('signupnexg/verifyotp', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.error_code = data['error_code'];
        if (Object.keys(data.result).length > 0) {
          this.email_otp_success_msg = data.error_string;
          this.email_otp_error = data.error_string;
          this.addEmailOTPForm.reset();
          this.updatePrfileEmail({ 'catlogue': this.catlogue, 'emailaddress': email, otp: otp });
          this.profileDetails();
          this.getSubscriptionDetails();
          this.addEmailOTPForm.reset();
        } else {
          this.email_otp_error = data.error_string;
        }

      },
        error => {
          this.email_otp_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }

  closeEmailOTPBtn(){
    this.error_code = '';
    this.email_error  ='';
    this.email_otp_success_msg = '';
    this.addEmailOTPForm.reset();
  }

  updatePrfileEmail(jsonArray: any) {

    this.email_error = '';
    this.email_otp_success_msg = '';

    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(jsonArray);
    this.apiService.postData('updateprofile', urlEncodedBodyData)
      .subscribe((data: any) => {

        this.error_code = data['error_code'];
        if (data.result) {

          this.email_error = data.error_string;
          this.closeEmailbutton.nativeElement.click(); // close the name field modal
          this.closeEmailOTPModal.nativeElement.click();
          this.showEmailSuccess.nativeElement.click();
          this.registredEmail = jsonArray.emailaddress;
          this.addEmailForm.reset();
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        } else {
          this.email_error = data.error_string;
        }

      },
        error => {
          this.email_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }

  showOldPass() {
    console.log(this.oldpass.nativeElement.type);
    let x = this.oldpass.nativeElement.type;
    if (x === "password") {
      this.oldEyeEvent.nativeElement.setAttribute('class', 'fa fa-eye');
      this.oldpass.nativeElement.setAttribute('type', 'text');

    } else {
      this.oldpass.nativeElement.setAttribute('type', 'password');
      this.oldEyeEvent.nativeElement.setAttribute('class', 'fa fa-eye');
    }
  }

  showNewPass() {
    let x = this.npass.nativeElement.type;
    if (x === "password") {
      this.newEyeEvent.nativeElement.setAttribute('class', 'fa fa-eye');
      this.npass.nativeElement.setAttribute('type', 'text');
    } else {
      this.npass.nativeElement.setAttribute('type', 'password');
      this.newEyeEvent.nativeElement.setAttribute('class', 'fa fa-eye');
    }
  }
  showCPass() {
    console.log(this.confirmPass.nativeElement.type);
    let x = this.confirmPass.nativeElement.type;
    if (x === "password") {
      this.confirmEyeEvent.nativeElement.setAttribute('class', 'fa fa-eye');
      this.confirmPass.nativeElement.setAttribute('type', 'text');
    } else {
      this.confirmPass.nativeElement.setAttribute('type', 'password');
      this.confirmEyeEvent.nativeElement.setAttribute('class', 'fa fa-eye');
    }
  }

  changePassword() {
    console.log(this.changePasswordForm);
    if (this.changePasswordForm.invalid) {
      return false;
    }
    this.wrongOldPwd = '';
    this.wrongNewPwd = '';
    this.wrongCnfrmPwd = '';
    let mobile = this.registered_mobile;
    let oldpass = this.changePasswordForm.controls.old_pass.value.trim();
    let newpass = this.changePasswordForm.controls.new_pass.value.trim();
    let cpass = this.changePasswordForm.controls.confirm_pass.value.trim();

    if (oldpass.length < 8 || newpass.length < 8 || cpass.length < 8) {

      if (oldpass.length < 8) {
        this.wrongOldPwd = 'Old password should be at least 8 characters.';

      }
      if (newpass.length < 8) {
        this.wrongNewPwd = 'New password should be at least 8 characters.';

      }
      if (cpass.length < 8) {
        this.wrongCnfrmPwd = 'Confirm password should be at least 8 characters.';

      }

      return false;

    }




    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(
      {
        'catlogue': this.catlogue,
        'mobile': mobile,
        'oldpass': oldpass,
        'pass': newpass.trim(),
        'cpass': cpass.trim()
      });
    this.apiService.postData('signupnexg/updatepass', urlEncodedBodyData)
      .subscribe((data: any) => {

        this.error_code = data['error_code'];
        if (data.error_code == "200") {
          this.changePasswordClose.nativeElement.click(); // close the input field modal
          this.changePasswordSuccess.nativeElement.click(); // show otp box
          this.change_password_error = '';
          this.changePasswordForm.reset();
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        } else {
          // this.wrongOldPwd = data.key_errors.oldpass[0];
          if (data.key_errors.oldpass != undefined) {
            this.wrongOldPwd = data.key_errors.oldpass[0];
          } else {
            this.wrongOldPwd = data.error_string;
          }

        }

      },
        error => {
          this.change_password_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );

  }

  changePassClose(){
    this.change_password_error = '';
    this.wrongOldPwd = '';
    this.wrongCnfrmPwd = '';
    this.wrongNewPwd = '';
    this.changePasswordForm.reset();
  }

  sentMobileOTP() {
    this.enableResendOTP = true;
    console.log(this.addMobileForm);
    if (this.addMobileForm.invalid) {
      return false;
    }
    // this.isMobileOtpResend = false;
    // this.mobile_error = '';
    // this.otp_success_msg = '';
    let mobile = this.addMobileForm.controls.mobile_number.value;
    this.mobile_name_text = this.addMobileForm.controls.mobile_number.value;
    let sessionId = localStorage.getItem('sessionId');
    mobile = mobile.trim();
    if (mobile.length < 10 || mobile.length > 10) {
      this.mobile_error = 'Mobile number must be at least 10 digit long.';
      return false;
    }
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'catlogue': this.catlogue, 'mobile': mobile, sessionId: sessionId });
    this.apiService.postData('signupnexg/resetpass', urlEncodedBodyData)
      .subscribe((data: any) => {

        if(data.error_string.includes("Contact")){
          this.mobile_error = '';
          this.mobile_error_string = data.error_string.split("Contact"); 
          this.fristSring = this.mobile_error_string[0];
          this.secondSring = this.mobile_error_string[1];
        }else{
          this.fristSring ='';
          this.mobile_error = data.error_string;
        }

         this.resendMobileOtpTimer();
        this.error_code = data.error_code;
        this.mobile_error = data.error_string;
        this.mobile_error_string = data.error_string;
        this.mobile_otp_error = data.error_string;

        if (Object.keys(data.result).length == 0) {
          this.mobile_error_string = data.error_string;
        } else {
          this.closeMobilebutton.nativeElement.click(); // close the name field modal
          this.showOTPModal.nativeElement.click(); // show otp box
        }
      },
        error => {
          this.mobile_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );

  }

  closeMobileBtn() {
    this.addMobileForm.reset();
    this.mobile_error = '';
    this.mobile_error_string = '';
  }


  resendMobileOTP(activateTimer?: any) {
    
    this.enableResendOTP = false;
    this.isMobileOtpResend = false;
    this.addMobileOTPForm.controls.verify_otp.setValue('');
    let mobile = this.mobile_name_text;
    this.mobile_otp_error = '';
    this.otp_success_msg = '';
    if (this.mobile_name_text == '') {
      this.mobile_otp_error = "Something went wrong. Please try after some time."
      return false;
    }
    let sessionId = localStorage.getItem('sessionId');
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'catlogue': this.catlogue, 'mobile': mobile, sessionId: sessionId });
    this.apiService.postData('signupnexg/resetpass', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.error_code = data['error_code'];
        // this.mobile_otp_error = data.error_string;
        // this.enableResendOTP = true;
        // if (data.error_code != '429') {
        this.resendMobileOtpTimer();
        // }
        if (Object.keys(data.result).length > 0) {
          this.otp_success_msg = data.error_string;
          // this.mobile_error = data.error_string;
          this.mobile_otp_error = data.error_string
          this.mobile_error_string = data.error_string;


          // if (data.error_string.includes("Contact")) {
          //   this.mobile_error = '';
          //   this.mobile_error_string = data.error_string.split("Contact");
          //   this.fristSring = this.mobile_error_string[0];
          //   this.secondSring = this.mobile_error_string[1];
          //   // this.mobile_error  = this.fristSring +"<span><a  routerLink='/contactus' >Contact</a></span>" + this.secondSring;
          // } else {
          //   this.mobile_error = data.error_string;
          // }

        } else {
          // if (data.error_string.includes("Contact")) {
          //   this.mobile_error_string = data.error_string.split("Contact");
          //   this.fristSring = this.mobile_error_string[0];
          //   this.secondSring = this.mobile_error_string[1];
          //   // this.mobile_error_contact  = this.fristSring + '<a routerLink="/contactus" routerLinkActive="active">Contact</a>' + this.secondSring;
          // } else {
          //   this.mobile_error = data.error_string;
          // }
          // this.mobile_error = data.error_string;
        }
      },
        error => {
          this.mobile_otp_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );

  }


  editMobileNumber() {
    this.addMobileForm.reset();
    this.addMobileOTPForm.reset();
    this.mobile_error = '';
    this.otp_success_msg = '';
    this.closeOTPModal.nativeElement.click();
    this.openMobileNumberModal.nativeElement.click();
  }

  clearMobileError(evt, screen_type: string) {


    var charCode = (evt.which) ? evt.which : evt.keyCode;

    if (charCode != 13) {
      this.mobile_error = '';
    }

    if (charCode == 13 && screen_type == 'mobile') {
    }

    // this.isNumberKey(evt);


    //let patt = /[0-9]|\./;// /^([0-9])$/;
    //let isNumber = patt.test(this.addMobileForm.controls.mobile_number.value);
    // console.log(isNaN(this.addMobileForm.controls.mobile_number.value));
    // console.log(isNumber);
    // if(!isNumber)

    // if(isNaN(this.addMobileForm.controls.mobile_number.value))
    // this.addMobileForm.controls.mobile_number.setValue('');

    // if(isNaN(this.addMobileOTPForm.controls.verify_otp.value))
    // this.addMobileOTPForm.controls.verify_otp.setValue('');
  }
  isNumberKey(event) {
    //   console.log('isNumberKey');

    //   var theEvent = event || window.event;

    // // Handle paste
    // if (theEvent.type === 'paste') {
    //     key = event.clipboardData.getData('text/plain');
    // } else {
    // // Handle key press
    //     var key = theEvent.keyCode || theEvent.which;
    //     key = String.fromCharCode(key);
    // }
    // var regex = /[0-9]|\./;
    // if( !regex.test(key) ) {
    //   theEvent.returnValue = false;
    //   if(theEvent.preventDefault) theEvent.preventDefault();
    // }
  }


  verifyMobileOTP() {

    this.mobile_otp_error = '';
    this.otp_success_msg = '';
    if (this.addMobileOTPForm.invalid) {
      return false;
    }


    let mobile = this.mobile_name_text;

    let otp = this.addMobileOTPForm.controls.verify_otp.value;

    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'catlogue': this.catlogue, 'mobile': mobile, otp: otp });
    this.apiService.postData('signupnexg/verifyotp', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.error_code = data['error_code'];
        if (Object.keys(data.result).length > 0) {
          this.otp_success_msg = ''; data.error_string;
          this.updatePrfileMobile({ 'catlogue': this.catlogue, 'mobile': mobile, otp: otp });
          this.profileDetails();
          this.getSubscriptionDetails();
          this.addMobileOTPForm.reset();
          this.showMobileSuccess.nativeElement.click();
          setTimeout(() => {
            window.location.reload();
          }, 1500)

        } else {
          this.mobile_otp_error = data.error_string;
        }
        console.log(this.mobile_error);

      },
        error => {
          this.mobile_otp_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }
  updatePrfileMobile(jsonArray: any) {

    this.mobile_error = '';
    this.otp_success_msg = '';

    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(jsonArray);
    this.apiService.postData('updateprofile', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.error_code = data['error_code'];
        if (data.result) {

          this.closeMobilebutton.nativeElement.click(); // close the name field modal
          this.closeOTPModal.nativeElement.click();
          this.showMobileSuccess.nativeElement.click();
          this.closeProfileForm.nativeElement.click();
          this.registredMobileNumber = jsonArray.mobile;
          this.addMobileForm.reset();
          setTimeout(() => {
            window.location.reload();
          }, 2000)
        } else {
          this.mobile_error = data.error_string;
        }

      },
        error => {
          this.mobile_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }

  dateFormat(dateString: string) {
    if (dateString == '')
      return '';

    var date = new Date(dateString);
    let newMomentObj = moment(date).format('MMMM YYYY');

    let ReadableDate = moment(date).format('DD-MM-YYYY');

    let formatedDate = moment("" + ReadableDate, 'DD-MM-YYYY').format('Do') + ' ' + newMomentObj;

    return formatedDate;
  }

  dateFormatText(dateString: string) {
    if (dateString == '')
      return '';
    console.log('dateString ' + dateString);
    var date = new Date(dateString);
    let newMomentObj = moment(date).format('DD/MM/YYYY');
    return newMomentObj;
  }

  getSubscriptionDetails() {
    console.log('getSubscriptionDetails');
    this.apiService.getData('subscribepack')
      .subscribe((data: any) => {

        if (Object.keys(data.result).length > 0) {

          this.packDetail = data.result;//[0];
          this.canBuy = data.can_buy == 'N' ? false : true;//[0];
          console.log(this.packDetail);
          // if (this.packDetail.customerStatus == 'A')
          //   this.isPlanActive = true;
          // else
          //   this.isPlanActive = false;

        } else {
          this.packDetail = '';
          this.isPlanActive = false;
        }

      },
        error => {
          this.packDetail = '';
          this.isPlanActive = false;
          this.name_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }
  showCancelButton(applicable_for: any) {
    if (Object.keys(applicable_for).length == 0) {
      return false;
    }
    console.log('applicable_for.indexOf(environment.platformType) ' + applicable_for.indexOf(environment.platformType));
    if (applicable_for.indexOf(environment.platformType) != -1)
      return true;
    else
      return false

  }
  removeSubscription() {

    if (this.cancelPack) {

      let userUnique = 'NA';
      if (this.registeredBy == 'mobile') {
        userUnique = this.registredMobileNumber;
      }
      if (this.registeredBy != 'mobile') {
        userUnique = this.registredEmail;
      }
      this.googleEvent.eventEmitter('my_account_subscription', 'my_account_subscription', 'Click on YES', 0, userUnique, 'Cancel Subscription confirmation popup');
      // this.googleEvent.eventEmitter("Yes", "Subscription Cancelled", userUnique + ' - My account');

      //   let otpSend:any;
      // if(this.registredMobileNumber != '')
      //    otpSend = {mobile  : this.packDetail.mobile,validity: this.packDetail.packValidity,pack_code: this.packDetail.packId,operator: this.packDetail.operatorId};

      //    if(this.registredEmail != '')
      //    otpSend = {mobile  : this.packDetail.mobile,validity: this.packDetail.packValidity,pack_code: this.packDetail.packId,operator: this.packDetail.operatorId};

      let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ mobile: this.cancelPack.mobile, validity: this.cancelPack.packValidity, pack_code: this.cancelPack.packId, operator: this.cancelPack.operatorId });
      this.apiService.postData('packremove', urlEncodedBodyData)
        .subscribe((data: any) => {
          this.error_code = data['error_code'];
          if (typeof data.result == 'string' || data.error_code == 200) {

            this.packExpiryDate = this.cancelPack.expiryDate;

            this.showPackSuccess.nativeElement.click();
            this.closeRemoveSubscriptionModal.nativeElement.click();
            this.isPlanActive = false;
            this.getSubscriptionDetails();
          } else {
            this.remove_subscription_error = data.error_string;
          }

        },
          error => {
            this.remove_subscription_error = 'Something went wrong!! Please try again after some time.';
          },
          () => {
          }
        );
    } else {
      this.remove_subscription_error = 'Something went wrong!! Please try again after some time.';
    }

  }
  applypromoCode() {

    if (this.addPromoForm.invalid) {
      return false;
    }

    let userUnique = 'NA';
    if (this.registeredBy == 'mobile') {
      userUnique = this.registredMobileNumber;
    }
    if (this.registeredBy != 'mobile') {
      userUnique = this.registredEmail;
    }

    let appliedCode = this.addPromoForm.controls.promocode.value;
    // this.googleEvent.eventEmitter(appliedCode, 'Redeem', userUnique + ' - My Account');
    this.googleEvent.eventEmitter('my_account_subscription', 'my_account_subscription', userUnique + "-Redeem-" + appliedCode + "-" + 'applied', 0, userUnique, userUnique + "-Redeem-" + appliedCode + "-" + 'applied');
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded({ 'couponcode': this.addPromoForm.controls.promocode.value, 'charge_code': '', 'catlogue': this.catlogue, 'platform': 'web' });
    this.apiService.postData('activationcode', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.error_code = data['error_code'];
        if (data.error_code == '200') {
          this.isPlanActive = true;
          this.closePromoButton.nativeElement.click(); // close the name field modal
          this.add_promocodesuccess.nativeElement.click(); // show success
          this.getSubscriptionDetails();
          this.addPromoForm.reset();
        } else {
          this.promocode_error = data.error_string;
        }

      },
        error => {
          this.promocode_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
    // this.googleEvent.eventEmitter('payment_gateway_checkout', 'payment_gateway_checkout', userUnique+"-"+this.selected_payment_gateway+"-"+appliedCode+"-" + this.paymentArray.amount, 0, this.userUnique, this.userUnique+"-"+this.selected_payment_gateway+"-"+appliedCode+"-" + this.paymentArray.amount);
  }

  sendParentalOtp(activateTimer?: any) {
    this.addParentalForm.reset();
    let data = {
      'catlogue': this.catlogue
    }
    this.isParentOtpResend = false;
    this.parental_otp_error = '';
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(data);
    this.apiService.postData('signupnexg/sendpotp', urlEncodedBodyData)
      .subscribe((data: any) => {
        console.log('sendParentalOtp', data);
        this.error_code = data['error_code'];
        this.parental_otp_success = data.error_string;
        this.parental_otp_error = data.error_string;
        if (data.result) {
          // this.parental_otp_success = data.error_string;
          // this.parental_otp_error = data.error_string;

          if (activateTimer == 1) {
            console.log('activateTimer');
            this.resendParentalOtpTimer();
            this.isParentOtpResend = true;
          } else {
            this.resendParentalOtpTimer();
          }

        } else {
          this.parental_otp_error = data.error_string;
        }

      },
        error => {
          this.parental_otp_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }

  submitParentalOtp() {
    this.parental_otp_success = '';
    this.parental_otp_error = '';
    if (this.addParentalForm.invalid) {
      return false;
    }
    this.isParentOtpResend = false;
    let data = {
      'catlogue': this.catlogue,
      'parental_control': this.parental_control == '0' ? '1' : '0',
      'otp': this.addParentalForm.controls.parental_enter_otp.value
    }
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(data);
    this.apiService.postData('signupnexg/parentalcontrol', urlEncodedBodyData)
      .subscribe((data: any) => {
        this.error_code = data['error_code'];
        this.parental_otp_success = data.error_string;
        if (Object.keys(data.result).length > 0) {
          this.parental_control_success_text = this.parental_control == '1' ? 'disabled' : 'enabled',
            this.profileDetails();
          this.closeParentalForm.nativeElement.click();
          setTimeout(() => {
            this.showParentalSuccess.nativeElement.click();
            window.location.reload();


          }, 800);

          let userUnique = 'NA';
          if (this.registeredBy == 'mobile') {
            userUnique = this.registredMobileNumber;
          }
          if (this.registeredBy != 'mobile') {
            userUnique = this.registredEmail;
          }
          this.resendParentalOtpTimer();
          // this.googleEvent.eventEmitter(this.parental_control_success_text, "My Account_Childlock", userUnique);
          this.googleEvent.eventEmitter('my_account_childlock', 'my_account_childlock', '', 0, userUnique, this.parental_control_success_text);

        } else {
          this.parental_otp_error = data.error_string;
        }

      },
        error => {
          this.parental_otp_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );

  }

  removeParentalError(evt) {

    var charCode = (evt.which) ? evt.which : evt.keyCode;

    if (charCode != 13) {
      this.parental_otp_error = '';
    }

    // this.parental_otp_error = '';
    // if(isNaN(this.addParentalForm.controls.parental_enter_otp.value))
    // this.addParentalForm.controls.parental_enter_otp.setValue('');
  }
  clearError(evt, passField) {
    console.log(passField);

    var charCode = (evt.which) ? evt.which : evt.keyCode;

    if (passField == 'oldpass' && charCode != 13) {
      this.wrongOldPwd = '';
    }
    if (passField == 'newpass' && charCode != 13) {
      this.wrongNewPwd = '';
    }
    if (passField == 'cnfrmpass' && charCode != 13) {
      this.wrongCnfrmPwd = '';
    }
  }


  resendMobileOtpTimer() {
    this.otpInt = resendOtpTimer.otpTimer;
    this.maxOtpAttempt = this.maxOtpAttempt - 1;
    this.startTimer(this.otpInt);
  }


  resendOptionalOtpTimer() {
    this.otpOptionalInt = resendOtpTimer.otpTimer;
    this.maxOtpAttempt = this.maxOtpAttempt - 1;
    this.startTimer(this.otpOptionalInt);
  }


  resendEmailOtpTimer() {
    this.emailotpInt = resendOtpTimer.otpTimer;
    this.maxEmailOtpAttempt = this.maxEmailOtpAttempt - 1;
    this.startTimer(this.emailotpInt);
  }

  resendOptionalEmailOtpTimer() {
    this.emailOptionalotpInt = resendOtpTimer.otpTimer;
    this.startTimer(this.emailOptionalotpInt);
  }

  resendParentalOtpTimer() {
    this.parentotpInt = resendOtpTimer.otpTimer;
    this.maxParentOtpAttempt = this.maxParentOtpAttempt - 1;
    this.startTimer(this.parentotpInt);
  }

  startTimer(duration) {
    let timer: any = duration;
    let minutes: any
    let seconds: any;
    let timerInterval = setInterval(() => {
      minutes = parseInt('' + timer / 60, 10);
      seconds = parseInt('' + timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      this.otpIntervalTimer = minutes + ":" + seconds;
      this.otpInt = minutes + ":" + seconds;
      this.emailotpInt = minutes + ":" + seconds;
      this.parentotpInt = minutes + ":" + seconds;
      this.emailOptionalotpInt = minutes + ":" + seconds;
      this.otpOptionalInt = minutes + ":" + seconds;
      this.showTimer = minutes + ":" + seconds;
      if (--timer < 0) {
        timer = duration;
        clearInterval(timerInterval);
      }

      console.log(timer);
    }, 1000);
  }

  objectLength(obj) {
    // console.log(obj);
    return Object.keys(obj).length;
  }

  closeCancelSubModal() {
    this.cancel_subscription_confirmation.nativeElement.click();
  }
}
