<div class="maincontentarea mt20">
    <div class="container">
       <div class="row">
           <div class="col-sm-12">               
               <div class="_about_content">
                  <h2>About us</h2>
                   <div class="_about_inner_c">
                        <p>Platform8 is the flagship entertainment OTT App of Channel Eight Virtual Estudios Limited. Channel Eight Virtual Estudios Limited is a Public Company incorporated in 1998. It is classified as a Non-govt company limited by Shares and is registered at Registrar of Companies, RoC-Kolkata. Channel Eight Virtual Estudios Limited had pledged to fulfil Bengal’s fancy towards art, culture, literature, and music. Now they are coming up with Bengal's exclusive OTT platform "Platform 8". Our expertise in delivering unique products to end consumers derives from a strong lineage technology and deep consumer insights. All product are developed keeping in mind changing trends, best in class content, ease of use, mass availability & affordability. For more details visit <a href="https://platform8.tv/">Platform8</a></p>
                   </div>
               </div>
           </div>
       </div>
    </div>
</div>