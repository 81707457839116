import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { UserService } from 'src/app/core';
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { AssetsService } from 'src/app/core/services/assets.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from "src/app/core/services/common.service";
declare var $: any;
@Component({
  selector: 'app-contest',
  templateUrl: './contest.component.html',
  styleUrls: ['./contest.component.css']
})
export class ContestComponent implements OnInit {
  // @ViewChild('openLoginllModel') openLoginModel: ElementRef;
  public contestResult:any="";
  public contetant:any;
  public msg:any;
  public eventId:any;
  public sessionId:any;
  public msgShow :boolean =false;
  public isSession :boolean;
  public isCloudFrontType: boolean = true;
  public notFoundImg:any;

  constructor(public userService: UserService, 
              public assetsService: AssetsService,
              public activatedRoute: ActivatedRoute,
              private modalService: NgbModal,
              public commonService: CommonService) { }

  ngOnInit(): void {
    this.notFoundImg = "square";
    if(this.commonService.filterType ==1){
      this.isCloudFrontType = true ;
    }else{
      this.isCloudFrontType = false;
    }

    this.activatedRoute.params.subscribe(params => {
      this.eventId = params['id'];
      this.sessionId = params['sessionId'];  
      if(params['sessionId']){
        this.isSession = true;
      }else{
        this.isSession = false;
      } 
    });

    if (localStorage.getItem('sessionId')=="60194480f127015d9011475a" && this.isSession==false) {
      $('#openLogin').trigger('click');
      //this.openLoginModel.nativeElement.click();
    } else {
      if(localStorage.getItem('sessionId')=="" || localStorage.getItem('sessionId')=="60194480f127015d9011475a" && this.isSession){
        localStorage.setItem('sessionId', this.sessionId);
      }
      this.getContest();
    }
    $("body").css({
      background: "-webkit-gradient(linear, left top, left bottom, from(rgba(22,36,47,1)), to(rgba(24,27,90,1))","min-height": "100vh"
  });




    // $("body").css({"background": "rgb(24,27,90)"},
    //               {"background" : "-moz-linear-gradient(0deg, rgba(24,27,90,1) 0%, rgba(22,36,47,1) 50%)"},
    //               {"background": "-webkit-linear-gradient(0deg, rgba(24,27,90,1) 0%, rgba(22,36,47,1) 50%)"},
    //               {"background": "linear-gradient(0deg, rgba(24,27,90,1) 0%, rgba(22,36,47,1) 50%)"},
    //               {"filter" : "progid:DXImageTransform.Microsoft.gradient(startColorstr='#181b5a',endColorstr='#16242f',GradientType=1)"});
    $('body').addClass("contest");
    $('body').removeClass("dark");
    $('app-header').remove();
    $('app-footer').remove();
    
  }

  openLoginModal(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((result) => {
    }, (reason) => {
    });

  }


getContest(){
  let temp = {
    catlogue: CatlougeTypeConstants.catlogue,
    call: "votingevent",
    votingeventid: this.eventId
  }

  let urlEncodedContestantParams = this.userService.convertoJSONToUrlEncoded(temp);
  this.assetsService.getContestDetail(urlEncodedContestantParams).subscribe(data => {
    if (data && data.result && data.result.result) {
      this.contestResult = data.result.result;
      this.msgShow= false;
    }else{
      this.msgShow = true;
      this.contestResult ="";
      $('body').addClass("votingend");
    }
  });
}

choseContestant(contetantId){
  $(".submit_btn").show();
  $("ul.vote li").removeClass("active");
  $("#contest"+contetantId).addClass("active");
  this.contetant = contetantId;
}

clickforVote(){
  let votedData = {
    votingeventid: this.eventId,
    contestantid: this.contetant
  }

  let urlEncodedOfSubmitContestantParams = this.userService.convertoJSONToUrlEncoded(votedData);
  this.assetsService.postContestDetail(urlEncodedOfSubmitContestantParams).subscribe(data => { 
          this.msg = data.error_string;
          $('#msgbutton').trigger('click');
  });
}
closeWin(){
  window.self.close(); 
}

}
