import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Errors, UserService, ApiService } from '../../../core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, } from '@ng-bootstrap/ng-bootstrap';
import { SearchService } from '../../../views/search/search.service';
import { AccountService } from '../../../views/account/account.service';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { LocalStorageService } from '../../../core/services/local-stroage.service'
import { CatlougeTypeConstants } from 'src/app/core/constants';
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
declare var $: any;


if (JSON.parse(localStorage.getItem('filterTypeData')) && JSON.parse(localStorage.getItem('filterTypeData')).search_engine && JSON.parse(localStorage.getItem('filterTypeData')).search_engine != 'algolia') {
  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: JSON.parse(localStorage.getItem('filterTypeData')).search_settings != "" ? JSON.parse(localStorage.getItem('filterTypeData')).search_settings.key : "", //"KaOiHOYPSv7GR9Ir1ESqQTyrG9gajAhU", // Be sure to use an API key that only allows search operations
      nodes: [
        {
          host: JSON.parse(localStorage.getItem('filterTypeData')).search_settings.host, //"stage-search.digivive.com",
          port: JSON.parse(localStorage.getItem('filterTypeData')).search_settings.port,
          protocol: JSON.parse(localStorage.getItem('filterTypeData')).search_settings.protocol,
          //host: "stage-search.digivive.com",
          // path: "", // Optional. Example: If you have your typesense mounted in localhost:8108/typesense, path should be equal to '/typesense'
          //port: 443,
          //protocol: "https",
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  query_by is required.
    additionalSearchParameters: {
      query_by: "name",
    },
  });

  var searchClient = typesenseInstantsearchAdapter.searchClient;
}


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userInfo: any;
  loginStatus: any;
  public isAuthenticated: any = false;
  @ViewChild('loginwindow') loginwindow;
  activeComponent: any;
  activeMenu: any = '';
  @ViewChild('searchTerm') searchTerm;
  @ViewChild('icl') icl: ElementRef;
  public loginModalContent: any;
  searchSubscriber: any;
  searchQuery = '';
  tempQueryStr: any;
  timezone: any;
  locale: any;
  timeZoneName: any;
  mobileSearchTerm: string = '';

  registeredBy: any;
  first_name_text: any;
  registredEmail: any;
  registredMobileNumber: any;
  parental_control: any;
  name_error: string;
  packDetail: any;
  canBuy: any;
  headerTabs: any = [];
  currentSlugName: any;
  getCurrentSlug: any;
  fristSlug: any;
  slugName: string;
  header: any;
  element: any;
  modalPopupStatus: boolean = true;
  errorCode: any;
  @ViewChild('openTellUsModel') openTellUsModel: ElementRef;
  errors1: { errors: {}; };
  public dynamicName;
  tellUsAllF: any = [];

  isTypeSonse = JSON.parse(localStorage.getItem('filterTypeData')).search_engine != undefined && JSON.parse(localStorage.getItem('filterTypeData')).search_engine == 'algolia' ? 0 : 1
  public checkSerchKey: any = 0;
  Currenturl: any = "";

  config1 = {
    indexName: JSON.parse(localStorage.getItem('filterTypeData')).search_engine != undefined && JSON.parse(localStorage.getItem('filterTypeData')).search_settings.collection,
    searchClient,
  };



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: UserService,
    private modalService: NgbModal,
    private serachSerarvice: SearchService,
    private apiService: AccountService,
    public activatedRoute: ActivatedRoute, private googleEvent: EventTrackingService,
    public localStorageService: LocalStorageService,
  ) {
    this.slugName = localStorage.getItem('slugName');

    this.dynamicTab();

    // this.activeComponent = route.snapshot.component;  
    // console.log(this.activeComponent.name);

    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.locale = Intl.DateTimeFormat().resolvedOptions().locale;
    this.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZoneName;
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.profileDetails();
  }



  ngOnInit(): void {
    var checkSearchVal = $(".searchVal").val.length;

    if (checkSearchVal <= 1) {
      $(".suggestion_search").css({ 'display': 'none' });
      $(".suggestion_search1").css({ 'display': 'none' });
    }

    document.body.scrollTop = 0;
    this.userService.activeMenu.subscribe((activeMenu) => {
      this.activeMenu = activeMenu;
      document.getElementById('navbarsExample04').classList.remove("show");
    });
    this.getCurrentSlug = this.userService.getCurrentSlugName;
    // alert(this.getCurrentSlug)
    if (this.activeMenu == undefined || this.activeMenu == null || this.activeMenu == '') {
      this.activeMenu = this.getCurrentSlug;
    }

    this.searchSubscriber = this.route.queryParams.subscribe(params => {
      this.searchQuery = params['q'] == undefined ? '' : params['q'];
      //this.userService.updateSelectedMenu('Search');
    });

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.isAuthenticated = localStorage.getItem('isAuthenticated');
    // this.userService.loginStatus(true);
    // this.loginwindow.nativeElement.click();
    this.userService.currentUser.subscribe((loginStatus) => {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.loginStatus = loginStatus;
      if (this.loginStatus == true || localStorage.getItem('isAuthenticated') == 'true') {
        localStorage.setItem('isAuthenticated', 'true')
        this.isAuthenticated = true;
        let da = "";
        this.userService.getTellUsFields(da).subscribe(data1 => {
          this.errorCode = data1['error_code'];
          if (this.errorCode == "200") {
            if (data1['result']['is_profile_complete'] == 0 && data1['result']['is_additional_profile_field_skipped'] == 0 && data1['result']['additional_field_data'].length > 0) {
              this.openTellUsModel.nativeElement.click();
            }

            this.dynamicName = "Hello";
            this.tellUsAllF = data1['result']['key_value_data'];
            this.tellUsAllF.forEach((item: any, index: any) => {
                if(item.slug=='name' && item.value!=''){
                  this.dynamicName = item.value;
                }
              
            })
          }

        }, err => {
          this.errors1 = err;
        });

      } else {
        this.isAuthenticated = false;

      }
    })
    // this.dynamicTab();

    $(".suggestion_search").css({ 'display': 'none' });
    $(".suggestion_search1").css({ 'display': 'none' });
    $(".desktop-search").addClass("hideSearch");


    document.addEventListener('click', function () {
      $(".suggestion_search").css({ 'display': 'none' });
    });


  }




  logout() {

    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('playListId');
    localStorage.removeItem('parentControl');
    localStorage.removeItem('debug');
    localStorage.removeItem('slugName');
    window.location.replace('/')

    // window.location.reload();
    // this.router.navigate(['/'])
    // window.location.reload();


  }

  addItem(newItem: boolean) {
    this.modalPopupStatus = newItem;
    // this.icl.nativeElement.click();
    // console.log(this.modalPopupStatus);
    this.openLoginModal(this.loginModalContent);
    //this.modalService.activeInstances[0].backdrop = newItem;
  }

  openLoginModal(content) {
    this.loginModalContent = content;
    console.log("test" + this.modalPopupStatus);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: this.modalPopupStatus }).result.then((result) => {
    }, (reason) => {
    });

  }

  openSignupModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });

  }

  openTellUs(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false, windowClass: 'tellu' }).result.then((result) => {
    }, (reason) => {
    });

  }

  closeModal() {

  }


  clearSearchData() {
    $(".suggestion_search").css({ 'display': 'none' });
    $(".suggestion_search1").css({ 'display': 'none' });
  }

  searchResult(e: any) {
    let term = e.target.value;
    this.checkSerchKey = term;
    $(".suggestion_search").css({ 'display': 'block' });
    $(".suggestion_search1").css({ 'display': 'block' });
    if (term.length <= 0) {
      $(".suggestion_search").css({ 'display': 'none' });
    }

    term = term.trim();
    if (term.length === 0 || !term.trim()) {
      return false;
    }

    if (term.length > 2 && localStorage.getItem('isAuthenticated') == undefined) {
      this.router.navigate(['search'], { queryParams: { 'q': term } })
    } else if (term.length > 2 && localStorage.getItem('isAuthenticated') == "true") {

      let isParentalControlEnabled = this.serachSerarvice.parentalControlStatus();

      if (isParentalControlEnabled == null) {
        // then hit the profile api for parental status
        this.apiService.getData('signupnexg/profile')
          .subscribe((data: any) => {

            if (data.result) {
              this.serachSerarvice.setParentalControl(data.result.parental_control);
              this.router.navigate(['search'], { queryParams: { 'q': term } });
            } else {
              this.serachSerarvice.setParentalControl(null);
            }

          },
            error => {
              this.serachSerarvice.setParentalControl(null);
            },
            () => {
            }
          );

      } else {
        this.serachSerarvice.setParentalControl(isParentalControlEnabled);
        this.router.navigate(['search'], { queryParams: { 'q': term } });
      }

    }
  }

  searchWithField(e: any) {
    $(".suggestion_search").css({ 'display': 'none' });
    let term = e;
    term = term.trim();
    if (term.length === 0 || !term.trim()) {
      return false;
    }

    if (term.length > 2 && localStorage.getItem('isAuthenticated') == undefined) {
      this.router.navigate(['search'], { queryParams: { 'q': term } })
    } else if (term.length > 2 && localStorage.getItem('isAuthenticated') == "true") {
      this.searchQuery = term;
      let isParentalControlEnabled = this.serachSerarvice.parentalControlStatus();

      if (isParentalControlEnabled == null) {
        // then hit the profile api for parental status
        this.apiService.getData('signupnexg/profile')
          .subscribe((data: any) => {

            if (data.result) {
              this.serachSerarvice.setParentalControl(data.result.parental_control);
              this.router.navigate(['search'], { queryParams: { 'q': term } });
            } else {
              this.serachSerarvice.setParentalControl(null);
            }

          },
            error => {
              this.serachSerarvice.setParentalControl(null);
            },
            () => {
            }
          );

      } else {
        this.serachSerarvice.setParentalControl(isParentalControlEnabled);
        this.router.navigate(['search'], { queryParams: { 'q': term } });
      }

    }
  }


  get allsearchParameters1() {
    //alert("yes");
    return {
      query: this.searchQuery
    }
  }
  
  submitSearch(mobileTerm?: string) {

    let term = this.searchTerm.nativeElement.value;
    term = term.trim();

    if (mobileTerm != undefined && (mobileTerm.length || !term.trim())) {
      term = mobileTerm.trim();
    }

    if (term.length === 0 || !term.trim()) {
      return false;
    } else if (localStorage.getItem('isAuthenticated') == undefined) {
      //this.serachSerarvice.setParentalControl(null);
      this.router.navigate(['search'], { queryParams: { 'q': term } });
    }

    else {

      let isParentalControlEnabled = this.serachSerarvice.parentalControlStatus();


      if (isParentalControlEnabled == null) {
        // then hit the profile api for parental status
        this.apiService.getData('signupnexg/profile')
          .subscribe((data: any) => {

            if (data.result) {
              this.serachSerarvice.setParentalControl(data.result.parental_control);
              this.router.navigate(['search'], { queryParams: { 'q': term } });
            } else {
              this.serachSerarvice.setParentalControl(null);
            }

          },
            error => {
              this.serachSerarvice.setParentalControl(null);
            },
            () => {
            }
          );

      } else {
        this.serachSerarvice.setParentalControl(isParentalControlEnabled);
        this.router.navigate(['search'], { queryParams: { 'q': term } });
      }

    }
  }

  submitMobileSearch() {
    let term = this.mobileSearchTerm;
    term = term.trim();
    this.submitSearch(term);
  }

  profileDetails() {
    this.apiService.getData('signupnexg/profile')
      .subscribe((data: any) => {

        if (Object.keys(data.result).length > 0) {
          this.registeredBy = data.result.register_by;
          this.first_name_text = data.result.first_name;
          this.registredEmail = data.result.emailaddress;
          this.registredMobileNumber = data.result.mobile;
          this.parental_control = data.result.parental_control;
          // this.searchSerarvice.setParentalControl(data.result.parental_control);

        }

      },
        error => {
          this.name_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }

  gotToTab() {
    this.slugName = this.fristSlug;
    localStorage.setItem('slugName', this.fristSlug);
    this.userService.updateSelectedMenu(this.fristSlug);
    this.userService.setCurrentSlugName(this.fristSlug);
    this.router.navigateByUrl('/' + this.fristSlug);
    window.scroll(0, 0);
    // window.location.reload();


  }

  highLightMenu(slug: any, name: any) {
    this.slugName = slug;
    this.activeMenu = slug;
    localStorage.setItem('slugName', slug);
    this.userService.updateSelectedMenu(slug);
    this.userService.setCurrentSlugName(slug);
    this.router.navigateByUrl('/' + slug);
    window.scroll(0, 0);
    // if(slug == 'home'){
    //   this.router.navigateByUrl('/');
    // }else{
    //   this.router.navigateByUrl('/'+slug);
    // }

  }

  dynamicTab() {

    let tabDataParams = {
      "catlogue": CatlougeTypeConstants.catlogue,
      "call": "gettabs"
    }

    let dynamicTabsRes = this.userService.convertoJSONToUrlEncoded(tabDataParams);

    this.userService.dynamicTab(dynamicTabsRes).subscribe((tabRes) => {
      this.fristSlug = tabRes.result[0].slug;
      if (tabRes.result && tabRes.result.length > 0) {
        this.headerTabs = tabRes.result;
        if (this.slugName == undefined || this.slugName == null || this.slugName == "") {
          this.slugName = tabRes.result[0].slug;
        }
        // for (let index = 0; index < tabRes.result.length; index++) {
        //   this.element = tabRes.result[index].slug;
        // }

        // localStorage.setItem('tabsLength', this.headerTabs.length);
        // this.fristSlug = tabRes.result[0].slug;
        // this.currentSlugName = this.userService.currentSlugName;
        // if (this.currentSlugName) {
        //   this.activeMenu = this.currentSlugName
        //   this.slugName = this.currentSlugName;
        //   // localStorage.setItem('currentSlugName',this.currentSlugName);
        //   this.userService.updateSelectedMenu(this.currentSlugName);
        //   this.userService.setCurrentSlugName(this.currentSlugName);
        // } else {
        //   this.activeMenu = tabRes.result[0].slug;
        //   this.slugName = tabRes.result[0].slug;
        //   // localStorage.setItem('currentSlugName',tabRes.result[0].slug);
        //   this.userService.updateSelectedMenu(tabRes.result[0].slug);
        //   this.userService.setCurrentSlugName(tabRes.result[0].slug);
        // }

        // this.userService.updateSelectedMenu(tabRes.result[0].slug);
        // this.userService.setCurrentSlugName(tabRes.result[0].slug);
        // this.router.navigateByUrl('/'+tabRes.result[0].slug);

        console.log(this.headerTabs);
      }

    })

  }
}
